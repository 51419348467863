import './home.scss';
import '../../assets/scripts/libs.jsx';
import '../../components/navigation/navigation.jsx';
import '../../components/footer/footer.jsx';
import {tns} from '../../node_modules/tiny-slider/dist/tiny-slider';
import Siema from 'siema';

tns({
    container: '.header-slider',
    items: 1,
    mode: 'gallery',
    speed: 800,
    slideBy: 'page',
    autoplayButtonOutput: false,
    controls: false,
    navPosition: 'bottom',
    autoplay: true
});

// import Siema from 'siema';
//
// const headerSlider = new Siema({
//     selector: '.header-slider',
//     loop: true,
//     duration: 800,
//     onChange: () => {
//         const buttons = document.querySelectorAll('.header-dots button')
//         buttons.forEach(elem => elem.classList.remove('active'));
//
//         buttons[headerSlider.currentSlide].classList.add('active');
//     }
// });
//
// Siema.prototype.addPagination = () => {
//     for (let i = 0; i < headerSlider.innerElements.length; i++) {
//         const btn = document.createElement('button');
//         if(i === 0) {
//             btn.classList.add('active');
//         }
//
//         btn.addEventListener('click', () => {
//             document.querySelectorAll('.header-dots button').forEach(elem => elem.classList.remove('active'));
//             headerSlider.goTo(i)
//             btn.classList.add('active');
//
//         });
//         document.querySelector('.header-dots').appendChild(btn);
//     }
// }
//
// headerSlider.addPagination();
//
// setInterval(() => {
//     headerSlider.next();
// }, 8000)



try {
    const investmentSliderBtnPrev = document.querySelector('.investments-section .btn-prev');
    const investmentSliderBtnNext = document.querySelector('.investments-section .btn-next');

    const investmentSlider = new Siema({
        selector: '.investment-slider',
        perPage: {
            991: 3
        },
        loop: true
    });

    investmentSliderBtnPrev.addEventListener('click', () => {
        investmentSlider.prev();
    })

    investmentSliderBtnNext.addEventListener('click', () => {
        investmentSlider.next();
    })
} catch (e) {
    console.log(e)
}



try {
    const realizationSliderBtnPrev = document.querySelector('.realizations-section .btn-prev');
    const realizationSliderBtnNext = document.querySelector('.realizations-section .btn-next');

    const realizationSlider = new Siema({
        selector: '.realization-slider',
        perPage: 1,
        loop: true
    });

    realizationSliderBtnPrev.addEventListener('click', () => {
        realizationSlider.prev();
    })

    realizationSliderBtnNext.addEventListener('click', () => {
        realizationSlider.next();
    })


} catch (e) {
    console.log(e)
}